<template>
  <div class="travelSpecsPrice">
<iframe scrolling: no
      :src="src"
      frameborder="0"
    ></iframe>
  </div>
</template>
<script>
import urls from "../../utils/baseUrl2";
export default {
  name: "travelSpecsPrice",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      src: `${urls.baseUrl}travelSpecsPrice/roomPriceIframe.html?specsId=${this.id}&isDevelopment=${urls.baseUrl}`,

      // src: `/static/travelSpecsPrice/roomPriceIframe.html?specsId=${this.id}`,
    };
  },
};
</script>
<style lang="less" scoped>
.travelSpecsPrice {
  height: 860px;

  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>